import { useRouteHistoryStore } from "@/stores/route-history.store";
import { useRouter } from "next/router";
import { useEffect } from "react";
import posthog from "posthog-js";

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

export function useRouteTracker() {
  const router = useRouter();
  const routeHistoryStore = useRouteHistoryStore();

  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("initialReferrer");
      const extractDomain = (url: any) => {
        try {
          const hostname = new URL(url).hostname;
          const match = hostname.match(/(?:www\.)?(?:l\.)?([^\.]+)\.\w+$/);
          return match
            ? match[1].charAt(0).toUpperCase() + match[1].slice(1)
            : "";
        } catch (e) {
          console.error("Error extracting domain: ", e);
          return "";
        }
      };
      const referrer = document.referrer;
      if (referrer) {
        const capitalizedDomain = extractDomain(referrer);
        if (
          capitalizedDomain !== "Padev" &&
          capitalizedDomain !== "Privateauto"
        ) {
          localStorage.setItem("initialReferrer", capitalizedDomain);
        }
      }
    }
  }, []);
  useEffect(() => {
    const handleRouteChange = () => {
      posthog?.capture("$pageview");
    };
    const onRouteChangeStart = () => {
      routeHistoryStore.registerRoute(router.asPath);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("routeChangeStart", onRouteChangeStart);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("routeChangeStart", onRouteChangeStart);
    };
  }, [router]);
}
